.drawerPaper {
    width: DRAWER_WIDTH;
  }

a{
  text-decoration: none;
  color: #666666;
}

.scrollbar
{
	float: left;
	background: #ffffff;
	overflow-y: scroll;
}

  #style-1::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  #style-1::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }
  
  #style-1::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }
  
  
  