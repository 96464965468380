

.App-logo {
  height: 100px;
  width: 100px;
  text-align: center;
  border: 2px solid gray;
  border-radius: 200px;
  pointer-events: none;
  }

  .logo-container h1{
    text-align: center;
  }
  
  input{
    min-height: 30px !important;
  }